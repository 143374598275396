<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>
      <div class="x12 text-big">
        <section><img class="size-full wp-image-23779 aligncenter" src="@/assets/news/26/1.png" alt="图片" width="600"  sizes="(max-width: 1080px) 100vw, 1080px"></section>
        <section>
          <section>
            <section>
              <section>当地时间9月10日下午，希腊总理米佐塔基斯在第86届塞萨洛尼基国际博览会上突然宣布：为了增加希腊当地人的可用房地产，<strong><strong>计划将希腊“黄金签证”<sup>*</sup>的购房门槛由原来的25万欧提升至50万欧！此言一出，立刻引起巨大反响。</strong></strong>&nbsp;<p></p>
              </section>
            </section>
          </section>
        </section>
        <section>这已经不是2022年的第一个涨价项目，从年初开始就有多个国家的移民项目进行了政策调整，有的涨价、有的提高申请要求、更有的直接关停，总体趋势不言而明，就是收紧、收紧、再收紧。<p></p>
          <section data-support="96编辑器" data-style-id="30063">
            <section>
              <section><strong>&nbsp;2022.1<br>
              </strong>葡萄牙正式关停里斯本、波尔图等核心城市的购房移民；同时基金方式的移民从35万欧元涨价到50万欧元2022.2<p></p>
                <p><strong>&nbsp;</strong><br>
                  英国200万英镑投资移民项目正式关停<br>
                  <strong><br>
                    2022.3<br>
                  </strong>美国发布EB-5改革和诚信法案：EB-5目标就业区（TEA地区）的投资门槛从50万美元上涨至80万美元；非目标就业区（非TEA地区）的投资门槛从100万美元涨至105万美元<br>
                  <strong><br>
                    2022.4<br>
                  </strong>英国首席代表签证关闭转永居通道<br>
                  <strong><br>
                    2022.4<br>
                  </strong>保加利亚国民议会在初读时一致通过废除“黄金护照”<sup>*</sup>项目（投资入籍计划）<br>
                  <strong><br>
                    2022.6<br>
                  </strong>土耳其护照正式从25万美金涨价至40万美金<br>
                  ……</p></section>
            </section>
          </section>
          <p>而关于希腊“黄金签证”<sup>*</sup>涨价的传言，近几年已被多次提起，变政方案也众说纷纭，譬如“涨价至35万”、“直接关停”、“按区域设定投资要求”等等，而这次总理的讲话直接一锤定音，确定未来希腊黄金签证的投资要求将直接翻倍！尽管最终的生效日期及具体细则还未正式公布，但可以确认的是，希腊这趟任选投资区域的25万欧元低成本移民列车即将驶达终点站，走进历史。</p>
          <p>针对这次突然“官宣”的涨价案，跃迁陈律师已亲抵雅典，与希腊现任发展和投资部长阿佐尼斯·乔治亚季斯（Adonis Georgiadis）约谈，了解议案详情，制定具体方案，助力客户赶上这趟变政前的末班车！</p>
        </section>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news26",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:26,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>